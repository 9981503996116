<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>Web Bot Flow</h5>
                <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan" style="background: lightgray;"> Web Bot Name -
                    {{ this.webBotData.mbu2 }}
                </Chip>
                <div>
                    <Button label="Web Bot Listing" class="p-button-sm p-mr-2" @click="goToListing"
                        icon="pi pi-th-large" />
                </div>
            </div>
            <div class="card" v-if="showSettingsStatus">
                <div class="p-fluid p-formgrid p-grid" style="margin-top: 2%;">
                    <div class="p-col-12">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-8">
                                <label for="next_template_name">
                                    Select Next Template
                                </label>
                                <Dropdown id="next_template_name" showClear filter v-model="next_template_name"
                                    :options="webBotTemplateList" optionLabel="label" placeholder="Select ..."
                                    appendTo="body" @change="onSelectNextTemplate">
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <DataTable :value="webBotFlowListing" v-model:selection="selectedProduct" :lazy="true"
                        :paginator="true" :rows="40" :totalRecords="totalRecords" :loading="loading"
                        @page="onPage($event)" class="p-datatable-users" data-key="mbs1" :rowHover="true"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">

                        <template #empty> No data found. </template>
                        <template #loading> Loading data Please wait... </template>
                        <Column header="Sr." headerStyle="width: 4%">
                            <template #body="{ index }">
                                <div>{{ genrateSrNo(index) }}</div>
                            </template>
                        </Column>
                        <Column fielthis.rowIdd="name" header="Web Bot Template Name" headerStyle="width: 50%">
                            <template #body="{ data }">
                                <div class="p-text-capitalize">{{ data.label ? data.label : 'N/A' }}</div>
                            </template>
                        </Column>
                        <Column field="body" header="Parent Group Id" headerStyle="width: 25%">
                            <template #body="{ data }">
                                <div class="p-text-capitalize">
                                    <div class="p-text"> {{ data.mbs3 }}</div>
                                </div>
                            </template>
                        </Column>
                        <Column header="Child Group Id" headerStyle="width: 25%">
                            <template #body="{ data }">
                                <div class="p-text-capitalize">
                                    <span v-if="data.mbs5"> {{ data.mbs5 }}</span>
                                    <span v-else> - </span>
                                </div>
                            </template>
                        </Column>
                        <Column header="Action" headerStyle="width: 15%">
                            <template #body="{ data, index }">
                                <Button icon="pi pi-cog" class="p-button-rounded"
                                    @click="getManageWebBotFlowSettings(data, index)" />
                            </template>
                        </Column>
                    </DataTable>
                </div>
                <Toast />
            </div>
        </div>
    </div>

    <manageWebBotFlowSettings :propsDataNew=currentWebBotId :propsDataNew1=webBotData :propsDataNew2=currentRowData
        :propsDataNew3=webBotFlowListing :propsDataNew4=index @go_to_previous="goToWebBotFlowListing"
        v-if="manageflowSettingsStatus" />

</template>



<script>
import ApiService from '../../service/ApiService';
import { useRoute } from 'vue-router';
import manageWebBotFlowSettings from './manageWebBotFlowSettings.vue';

export default {
    inject: ['global'],
    props: {
        propsData: {
            required: true,
        },
        propsData1: {
            required: true,
        },
    },
    emits: {
        go_to_previous: null,
    },
    components: {
        manageWebBotFlowSettings
    },
    data() {
        return {
            index: 0,
            localClientId: '',
            localClientName: '',
            routeParam: 0,
            webBotData: [],
            rowId: 0,
            webBotTemplateList: [],
            webBotFlowListing: [],
            loading: false,
            totalRecords: 0,
            next_template_name: '',
            currentWebBotId: '',
            manageflowSettingsStatus: false,
            currentRowData: [],
            showSettingsStatus: true,
            page_no: 0,
            selectedProduct: []
        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        const route = useRoute();
        this.routeParam = route.params.clientSubId;
        this.webBotData = this.propsData;
        this.webBotData = JSON.parse(localStorage.getItem('webBotData'));
        if (this.propsData1)
            this.rowId = this.propsData1;
        else
            this.rowId = localStorage.webBotId;
        this.localClientName = localStorage.localClientName;
        this.getWebBotFlowData();
    },
    methods: {
        goToListing() {
            this.$emit('go_to_previous');
        },
        goToWebBotFlowListing() {
            this.manageflowSettingsStatus = false;
            this.showSettingsStatus = true;
            this.getWebBotFlowData();
        },

        getManageWebBotFlowSettings(e, index) {
            this.currentWebBotId = e.mbu1;
            this.currentRowData = e;// JSON.stringify(e);
            this.manageflowSettingsStatus = true;
            this.showSettingsStatus = false;
            this.index = index;
        },
        getAllWebBotTemplateOnly() {
            this.ApiService.getAllWebBotTemplateOnly({ clientId: this.routeParam, webBotFlowListing: this.webBotFlowListing, webbotId: this.webBotData.mbu1 }).then((data) => {
                if (data.status == 200) {
                    this.webBotTemplateList = data.data;
                } else {
                    this.webBotTemplateList = '';
                }
            });
        },
        onSelectNextTemplate(templateName) {
            this.ApiService.addWebBotFlow({ templateData: templateName.value, clientId: this.routeParam, webBotData: this.webBotData, webBotFlowListing: this.webBotFlowListing }).then((data) => {
                if (data.status == 200) {

                    if (data.alreadyPresent == 0) {
                        var successMsg = data.message;
                        this.webBotFlowListing.push(templateName.value);
                        let arrConverted = data.data;
                        for (let i = 0; i < arrConverted.length; i++) {
                            let vals = this.webBotTemplateList.filter(function (item) {
                                return item.value == arrConverted[i]['mbs1'];
                            });
                            this.webBotFlowListing.push(vals[0]);
                        }
                        this.getAllWebBotTemplateOnly();
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });

                    } else {
                        var errorMsg = data.message
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });

                    }
                }
                this.totalRecords = this.webBotFlowListing.length;
            });
        },
        getWebBotFlowData() {
            this.loading = true;
            this.ApiService.getWebBotFlowData({ clientId: this.routeParam, webBotId: this.webBotData.mbu1 }).then((data) => {

                if (data.status == 200) {
                    this.webBotFlowListing = data.data;
                    this.totalRecords = this.webBotFlowListing.length;
                    this.getAllWebBotTemplateOnly();
                    this.loading = false;
                } else {
                    this.webBotFlowListing = '';
                    this.loading = false;
                }
            });
        }


    },
};
</script>
<style scoped lang="scss">
.plan-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-free {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-paid {
        background: #ffd8b2;
        color: #805b36;
    }
}

.plan-type-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-standard {
        background: #b3e5fc;
        color: #23547b;
    }

    &.status-premium {
        background: #eccfff;
        color: #694382;
    }
}
</style>
