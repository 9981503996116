<template>
    <Toast :baseZIndex="9999" />
    <!-- Add/Edit wTemplate starts here -->
    <div class="card">
        <div style="position: relative">
            <!-- Loader before form loads -->
            <div v-if="!formLoaded" class="loader-overlay">
                <i class="pi pi-spin pi-spinner loader-icon"></i>
            </div>
            <div v-else class="p-grid">
                <div class="p-col-12">
                    <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                        <h5>{{ this.dialogHeader }}
                            <Chip class="p-mr-1 p-mb-1 custom-multi-chip-vidhan" v-if="totalRecords.length != 0">
                                Template Name - {{ this.rowData.mbs2 }}
                            </Chip>
                        </h5>
                        <div>
                            <Button label=" Flow Listing" class="p-button-sm p-mr-2" @click="goToListing"
                                icon="pi pi-th-large" />
                            <Button label="Previous" class="p-button-sm p-mr-1" @click="goToPrevious"
                                icon="pi pi-chevron-left" v-if="count != 0 && totalRecords.length != 0" />
                            <Button label="Next" icon="pi pi-chevron-right" @click="goToNext" class="p-button-sm"
                                iconPos="right" v-if="count < totalRecords.length - 1 && totalRecords.length != 0" />
                        </div>
                    </div>
                    <div class="p-fluid p-formgrid p-grid" style="margin-top: 2%;">
                        <div class="p-field p-col-12">
                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-field p-col-12 p-md-4">
                                    <label>Header Status <span class="p-invalid p-error"> *</span></label>
                                    <div class="p-field-radiobutton">
                                        <RadioButton id="headerstatusavl" name="option" v-bind:value="1"
                                            v-model="headerstatus" :checked="headerstatus == 1" />
                                        <label for="headerstatusavl" class="p-mr-2">Available</label>
                                        <RadioButton id="headerstatusnotavl" name="option" v-bind:value="0"
                                            v-model="headerstatus" :checked="headerstatus == 0" />
                                        <label for="headerstatusnotavl" class="p-mr-2">Not Available</label>
                                    </div>
                                </div>
                                <div class="p-field p-col-12 p-md-4" v-if="headerstatus == 1">
                                    <label for="header_file_type">
                                        Header File Type <span class="p-invalid p-error">*</span>
                                    </label>
                                    <Dropdown id="header_file_type" showClear v-model="header_file_type"
                                        :options="headerFileTypeList" optionLabel="label" placeholder="Select ..."
                                        @change="clearuploadimage()" appendTo="body">
                                    </Dropdown>
                                    <div v-if="headerstatus == 1">
                                        <small class="p-invalid p-error" v-if="v$.header_file_type.$error">{{
                                            v$.header_file_type.$errors[0].$message
                                            }}</small>
                                    </div>
                                </div>
                                <div class="p-field p-col-12 p-md-4" v-if="headerstatus == 1 && header_file_type">
                                    <label>Upload Header <span class="p-invalid p-error">*</span></label>
                                    <div v-if="header_file_type.value == 1">
                                        <FileUpload v-model="header_file_url" mode="basic" ref="header_file_url"
                                            :maxFileSize="1000000" accept="image/*" chooseLabel="Upload Header Image"
                                            auto @select="onSelectProfile">
                                        </FileUpload>
                                        <span v-if="header_file_url_edit && profileUrl == null">
                                            <img class="p-pt-2" role="presentation" alt="edit"
                                                :src="header_file_url_edit" width="120" height="120" />
                                        </span>
                                    </div>
                                    <div v-if="header_file_type.value == 2">
                                        <FileUpload mode="basic" ref="docuploadref" class="custom-upload-btn-ultima"
                                            accept="pdf/*" v-model="header_file_url"
                                            chooseLabel="Upload Header Document" v-on:change="onSelectProfile()" />
                                    </div>
                                    <div v-if="header_file_type.value == 3">
                                        <FileUpload mode="basic" ref="videouploadref" class="custom-upload-btn-ultima"
                                            accept="mp4/*" v-model="header_file_url" chooseLabel="Upload Header Video"
                                            v-on:change="onSelectProfile()" />
                                    </div>
                                    <div v-if="header_file_type.value == 4">
                                        <FileUpload v-model="header_file_url" mode="basic" ref="tuneuploadeditref"
                                            :maxFileSize="1000000" accept="mp3/*" chooseLabel="Upload Header Tune" auto
                                            @select="onSelectProfile" />
                                    </div>
                                    <div v-if="header_file_type.value != 1">
                                        <a v-if="header_file_url_edit != '' && header_file_url_edit != null"
                                            class="view-btn" target="_blank" :href="header_file_url_edit">Preview</a>
                                    </div>
                                    <small class="p-invalid p-error" v-if="profileImageError">{{ profileImageError
                                        }}</small>
                                    <div v-if="headerstatus == 1 && header_file_type && profileImageError == ''">
                                        <small class="p-invalid p-error" v-if="v$.header_file_url.$error">{{
                                            v$.header_file_url.$errors[0].$message
                                            }}</small>
                                    </div>
                                    <div>
                                        <div v-if="header_file_type.value == 1">
                                            <img v-if="profileUrl" class="p-pt-2" role="presentation"
                                                :alt="profileUrl.name" :src="profileUrl.url" width="120" height="120" />
                                        </div>
                                        <div v-if="header_file_type.value != 1">
                                            <div class="p-field p-col-12 p-md-12" v-if="profileUrl != null">{{
                                                profileUrl.name
                                                }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Divider type="dashed" />
                    <div class="p-col-12">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-4">
                                <label for="template_reply_type">
                                    Reply Type
                                </label>
                                <Dropdown id="template_reply_type" v-model="template_reply_type"
                                    :options="templatereplytypeList" optionLabel="label" placeholder="Select ..."
                                    appendTo="body">
                                </Dropdown>
                            </div>
                            <div class="p-field p-col-12 p-md-4" v-if="this.template_reply_type.value == 1">
                                <label for="quickBtnCount">
                                    Select Quantity of Buttons
                                </label> 
                                <!-- <Dropdown id="quickBtnCount" v-model="quickBtnCount" :options="quickBtnCountList"
                                    optionLabel="label" placeholder="Select ..." appendTo="body"
                                    @change="createNumberOfQuickButtons">
                                </Dropdown> -->
                                <Dropdown id="quickBtnCount" showClear filter v-model="quickBtnCount"
                                    :options="quickBtnCountList" optionLabel="label" placeholder="Select ..."
                                    appendTo="body" @change="createNumberOfQuickButtons">
                                </Dropdown>
                            </div>
                            <!--start reply type = 14 (Text) -->

                            <div class="p-field p-col-12 p-md-4" v-if="this.template_reply_type.value == 14">
                                <label for="text_max_char_limit">
                                    Maximum Number Of Characters In Text
                                    <span class="p-invalid p-error" v-if="this.template_reply_type.value == 14">*
                                    </span>
                                </label>
                                <InputText id="text_max_char_limit" v-model.trim="text_max_char_limit" required="true"
                                    maxlength="5" @keypress="onlyNumberForMaxCharLimit" />
                            </div>
                            <!--end reply type = 14 (Text) -->

                        </div>
                    </div>
                    <!--start reply type = 1 (quick reply button) -->
                    <div class="p-col-12" v-if="this.quickBtnCount.value && this.template_reply_type.value == 1">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-4" v-if="LanguageAllowedForThisSetting.includes(1)">
                                <div class="p-field p-col-12 p-md-12 p-text-center">
                                    <h6>English</h6>
                                </div>
                                <Divider type="dashed" />
                                <div class="p-field p-col-12 p-md-12" v-for="(item, index) in newQuickBtnLabelArray"
                                    :key="index">
                                    <label for="'quickBtnName_' + index">
                                        {{ item.label }} Name
                                    </label>
                                    <InputText id="'quickBtnName_' + index" v-model.trim="newQuickBtnNameArrayR1[index]"
                                        required="true" maxlength="50" @input="showEnglishButtonName(index)" />
                                </div>
                            </div>
                            <div class="p-field p-col-12 p-md-4" v-if="LanguageAllowedForThisSetting.includes(5)">
                                <div class="p-field p-col-12 p-md-12 p-text-center">
                                    <h6>Hindi</h6>
                                </div>
                                <Divider type="dashed" />
                                <div class="p-field p-col-12 p-md-12" v-for="(item, index) in newQuickBtnLabelArray"
                                    :key="index">
                                    <label for="'quickBtnName_' + index">
                                        {{ item.label }} Name
                                    </label>
                                    <InputText id="'quickBtnName_' + index" v-model.trim="newQuickBtnNameArrayR2[index]"
                                        required="true" maxlength="50" @input="showHindiButtonName(index)" />
                                </div>
                            </div>
                            <div class="p-field p-col-12 p-md-4" v-if="regional_lang_flag">
                                <div class="p-field p-col-12 p-md-12 p-text-center">
                                    <h6>{{ getRegionalLanguage() }}</h6>
                                </div>
                                <Divider type="dashed" />
                                <div class="p-field p-col-12 p-md-12" v-for="(item, index) in newQuickBtnLabelArray"
                                    :key="index">
                                    <label for="'quickBtnName_' + index">
                                        {{ item.label }} Name
                                    </label>
                                    <InputText id="'quickBtnName_' + index" v-model.trim="newQuickBtnNameArrayR3[index]"
                                        required="true" maxlength="50" @input="showRegionalLangButtonName(index)" />
                                </div>
                            </div>
                            <div class="p-col-12" v-for="(item, index) in newQuickBtnLabelArray" :key="index">
                                <div class="p-fluid p-formgrid p-grid" v-if="this.quickBtnNameArray[index]">
                                    <div class="p-field p-col-12 p-md-4" v-if="item.value != ''">
                                        <label for="'quickBtnName_' + index">
                                            {{ item.label }} Name
                                            <span class="p-invalid p-error">*</span>
                                        </label>
                                        <InputText id="'quickBtnName_' + index" v-model.trim="quickBtnNameArray[index]"
                                            required="true" maxlength="50" disabled />
                                    </div>

                                    <div class="p-field p-col-12 p-md-8" v-if="item.value != ''">
                                        <label for="'quickBtnTemplateName_' + index">
                                            {{ item.label }} Template Name
                                            <span class="p-invalid p-error">*</span>
                                        </label>
                                        <Dropdown id="'quickBtnTemplateName_' + index" showClear filter
                                            v-model="quickBtnTemplateNameArray[index]" :options="templateEventList"
                                            optionLabel="label" placeholder="Select ..." appendTo="body">
                                        </Dropdown>
                                        <!-- <span class="p-invalid p-error">
                                            {{ $v.quickBtnTemplateNameArray[index].$error ? 'Please select a template' :
                                                '' }}
                                        </span> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Divider type="dashed" />
                    <!--end reply type =1 ( quick reply button) -->

                    <!--start reply type = 2 (Satic link button) -->
                    <div class="p-col-12" v-if="this.template_reply_type.value == 2">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-4">
                                <label for="staticLinkBtnName">
                                    Static Link Button Name
                                    <span class="p-invalid p-error" v-if="this.template_reply_type.value == 2">
                                        *
                                    </span>

                                </label>
                                <InputText id="staticLinkBtnName" v-model.trim="staticLinkBtnName" required="true"
                                    maxlength="50" />
                                <div v-if="this.template_reply_type.value == 2">
                                    <small class="p-invalid p-error" v-if="v$.staticLinkBtnName.$error">{{
                                        v$.staticLinkBtnName.$errors[0].$message
                                        }}</small>
                                </div>
                            </div>
                            <div class="p-field p-col-12 p-md-8">
                                <label for="staticLink">
                                    Static Link
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <InputText id="staticLink" v-model.trim="staticLink" required="true" maxlength="250" />
                                <small class="p-invalid p-error" v-if="v$.staticLink.$error">{{
                                    v$.staticLink.$errors[0].$message
                                    }}</small>
                            </div>
                        </div>
                    </div>
                    <!--end reply type = 2 (Satic link button) -->

                    <!--start reply type = 3 (Dynamic link button) -->
                    <div class="p-col-12" v-if="this.template_reply_type.value == 3">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-4">
                                <label for="dynamicLinkBtnName">
                                    Dynamic Link Button Name
                                    <span class="p-invalid p-error" v-if="this.template_reply_type.value == 3">
                                        *
                                    </span>

                                </label>
                                <InputText id="dynamicLinkBtnName" v-model.trim="dynamicLinkBtnName" required="true"
                                    maxlength="50" />
                                <div v-if="this.template_reply_type.value == 3">
                                    <small class="p-invalid p-error" v-if="v$.dynamicLinkBtnName.$error">{{
                                        v$.dynamicLinkBtnName.$errors[0].$message
                                        }}</small>
                                </div>
                            </div>
                            <div class="p-field p-col-12 p-md-8">
                                <label for="dynamicLink">
                                    Dynamic Link
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <InputText id="dynamicLink" v-model.trim="dynamicLink" required="true"
                                    maxlength="250" />
                                <small class="p-invalid p-error" v-if="v$.dynamicLink.$error">{{
                                    v$.dynamicLink.$errors[0].$message
                                    }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label for="dynamicsLinkVariable">
                                    Dynamic Link Variable
                                    <span class="p-invalid p-error">*</span>
                                </label>
                                <InputText id="dynamicsLinkVariable" v-model.trim="dynamicsLinkVariable" required="true"
                                    maxlength="50" />
                                <small class="p-invalid p-error" v-if="v$.dynamicsLinkVariable.$error">{{
                                    v$.dynamicsLinkVariable.$errors[0].$message
                                    }}</small>
                            </div>
                        </div>
                    </div>
                    <!--end reply type = 2 (Satic link button) -->

                    <!--start reply type = 13 (OTP) -->
                    <div v-if="this.template_reply_type.value == 13" style="color: green; margin-bottom: 1%;">
                        The number of digits in OTP should be between 3 and 6.
                    </div>

                    <div class="p-col-12" v-if="this.template_reply_type.value == 13">
                        <div class="p-fluid p-formgrid p-grid">

                            <div class="p-field p-col-12 p-md-4">

                                <label for="otp_min_limit">
                                    Minimum number of digits in OTP
                                    <span class="p-invalid p-error" v-if="this.template_reply_type.value == 13">*
                                    </span>
                                </label>
                                <InputText id="otp_min_limit" v-model.trim="otp_min_limit" required="true" maxlength="1"
                                    @keypress="onlyNumber" />
                                <div v-if="this.template_reply_type.value == 13">
                                    <small class="p-invalid p-error" v-if="v$.otp_min_limit.$error">{{
                                        v$.otp_min_limit.$errors[0].$message
                                        }}</small>
                                </div>
                            </div>
                            <div class="p-field p-col-12 p-md-4">
                                <label for="otp_max_limit">
                                    Maximum number of digits in OTP
                                </label>
                                <InputText id="otp_max_limit" v-model.trim="otp_max_limit" required="true" maxlength="1"
                                    @keypress="onlyNumber" />
                            </div>
                        </div>
                    </div>
                    <!--end reply type = 13 (OTP) -->
                    <!-- multiflow template -->
                    <div class="p-col-12" v-for="(item, index) in multiflowTempList" :key="index">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-4"
                                v-if="this.rowData && (this.rowData.mbs4 == 2 || this.rowData.mbs4 == 5)">
                                <label for="error_code">
                                    Response Code
                                </label>
                                <Dropdown id="error_code" showClear filter v-model="error_code[index]"
                                    :options="errorCodeList" optionLabel="label" placeholder="Select ..."
                                    appendTo="body">
                                </Dropdown>
                            </div>
                            <div class="p-field p-col-12 p-md-8"
                                v-if="this.rowData && (this.rowData.mbs4 == 2 || this.rowData.mbs4 == 5)">
                                <label for="multiple_flow_templ">
                                    Multiflow Template Name
                                </label>
                                <Dropdown id="multiple_flow_templ" showClear filter v-model="multiple_flow_templ[index]"
                                    :options="multiflowTempList" optionLabel="label" placeholder="Select ..."
                                    appendTo="body">
                                </Dropdown>
                            </div>
                        </div>

                    </div>
                    <!-- Error code + input requesting -->
                    <div class="p-col-12">
                        <div class="p-fluid p-formgrid p-grid">

                            <div class="p-field p-col-12 p-md-8" v-if="this.rowData && (this.rowData.mbs4 == 4)">
                                <label for="err_reporting_templ">
                                    Error Reporting Template For Success <span class="p-invalid p-error">*</span>
                                </label>
                                <Dropdown id="err_reporting_templ_succ" showClear filter
                                    v-model="err_reporting_templ_succ" :options="templateEventList" optionLabel="label"
                                    placeholder="Select ..." appendTo="body">
                                </Dropdown>
                                <small class="p-invalid p-error" v-if="v$.err_reporting_templ_succ.$error">{{
                                    v$.err_reporting_templ_succ.$errors[0].$message
                                    }}</small>
                            </div>
                            <div class="p-field p-col-12 p-md-8" v-if="this.rowData && (this.rowData.mbs4 == 4)">
                                <label for="err_reporting_templ">
                                    Error Reporting Template For Error <span class="p-invalid p-error">*</span>
                                </label>
                                <Dropdown id="err_reporting_templ_err" showClear filter
                                    v-model="err_reporting_templ_err" :options="templateEventList" optionLabel="label"
                                    placeholder="Select ..." appendTo="body">
                                </Dropdown>
                                <small class="p-invalid p-error" v-if="v$.err_reporting_templ_err.$error">{{
                                    v$.err_reporting_templ_err.$errors[0].$message
                                    }}</small>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12">
                        <div class="p-fluid p-formgrid p-grid">
                            <div class="p-field p-col-12 p-md-8">
                                <label for="next_template_name">
                                    Next Template Name
                                </label>
                                <Dropdown id="next_template_name" showClear filter v-model="next_template_name"
                                    :options="templateEventList" optionLabel="label" placeholder="Select ..."
                                    appendTo="body">
                                </Dropdown>
                            </div>
                        </div>
                    </div>

                    <Button label="Submit" :icon="!showLoader ? 'pi pi-check' : 'pi pi-spin pi-spinner indigo-color'"
                        class="p-button-text p-ml-auto" @click="addNewWebBotTemplate" style="width: 90px;"
                        :disabled="showLoader"></Button>
                </div>
            </div>
        </div>
    </div>
    <!-- Add/Edit wTemplate ends here -->
</template>

<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import { useRoute } from 'vue-router';
import { required, helpers, requiredIf } from '@vuelidate/validators';
import { inject } from 'vue';

export default {
    inject: ['global'],
    props: {
        propsDataNew: {
            required: false,
        },
        propsDataNew1: {
            required: true,
        },
        propsDataNew2: {
            required: true,
        },
        propsDataNew3: {
            required: true,
        },
        propsDataNew4: {
            required: true,
        }
    },
    emits: {
        go_to_listing: null,
    },
    data() {
        const globalData = inject('global');
        return {
            button_template_name: [],
            templateEventList: [],
            v$: useValidate(),
            showLoader: false,
            formLoaded: false,
            expandedRows: [],
            loading: false,
            page_no: 0,
            totalRecords: 0,
            isEditwTemplate: false,
            fromDate: null,
            toDate: null,
            todaysDate: new Date(),
            dialogHeader: "Update Web Bot Template Settings",
            validation: {},
            profileImageError: '',

            languageList: [],
            // currentChatBotData: [],
            // webBotTemplateGroupData: [],
            templatereplytypeList: globalData.state.templateReplyTypeList,
            //m  new start
            quickBtnCountList: [
                { label: "1", value: 1 },
                { label: "2", value: 2 },
                { label: "3", value: 3 },
                { label: "4", value: 4 },
                { label: "5", value: 5 },
                { label: "6", value: 6 },
                { label: "7", value: 7 },
                { label: "8", value: 8 },
                { label: "9", value: 9 },
                { label: "10", value: 10 },
            ],
            quickBtnLabelArray: [
                { label: "First Button ", value: 1 },
                { label: "Second Button ", value: 2 },
                { label: "Third Button ", value: 3 },
                { label: "Fourth Button ", value: 4 },
                { label: "Fifth Button ", value: 5 },
                { label: "Sixth Button ", value: 6 },
                { label: "Seventh Button ", value: 7 },
                { label: "Eighth Button ", value: 8 },
                { label: "Ninth Button ", value: 9 },
                { label: "Tenth Button ", value: 10 }],

            quickBtnNameArray: [],
            quickBtnTemplateNameArray: [],
            quickBtnCount: '',
            newQuickBtnLabelArray: [],
            newQuickBtnNameArrayR1: [],
            newQuickBtnNameArrayR2: [],
            newQuickBtnNameArrayR3: [],

            staticLinkBtnName: '',
            staticLink: '',

            dynamicLinkBtnName: '',
            dynamicLink: '',
            dynamicsLinkVariable: '',

            otp_min_limit: '',
            otp_max_limit: '',

            text_max_char_limit: '',

            // m new ends
            chatbot: '',
            submitted: false,
            templateBodyL1: '',
            templateBodyL2: '',
            templateBodyL3: '',
            language: '',
            template_reply_type: '',
            next_template_name: '',
            currentMultiFlowTempList: [],

            header_file_type: '',
            headerFileTypeList: globalData.state.headerFileTypeList,
            headerstatus: 0,
            header_file_url: null,
            header_file_url_edit: null,
            profileUrl: null,
            allowedExtensions: '',
            docuploadref: '',
            videouploadref: '',
            tuneuploadeditref: '',
            file: '',
            mbv_row_id: [],
            foundUniqueIdErrorMsg: '',

            isCopyWhatsappSetting: false,
            selectedProduct: null,
            copysettingloader: false,
            clientList: [],
            selectedWhatsAppSettings: [],
            client_name: '',
            localClientName: '',
            count: 0,
            index: 2,
            templ_lang_ids: '1,5,2',
            templ_lang_vals: 'English,Hindi,Marathi',
            regional_lang_flag: false,
            rowData: null,
            currentTemplData: null,
            multiflowTempList: [],
            errorCodeList: [
                { value: 1, label: "Success" },
                { value: 2, label: "Duplicate Record" },
                { value: 3, label: "Invalid Entry" },
                { value: 4, label: "Operator Side Error" },
                { value: 5, label: "Record Exist With Same Voter" },
                { value: 6, label: "OTP As Last 3 digits Of Mobile Number" },
                { value: 7, label: "Invalid OTP, New OTP Sent" },
                { value: 8, label: "Auto Fetch voter card" },
            ],
            error_code: [],
            multiple_flow_templ: [],

            // errorReportingList: [],
            // err_reporting_templ: [],

            templ_lang_ids_count: 0,
            currentMultiflowTempCount: 0,
            regionLangaugeId: 0,
            multiFlowIds: [],

            chatBotFlow: [],
            LanguageAllowedForThisSetting: [],
            settingLanguageValues: [],

            err_reporting_templ_err: [],
            err_reporting_templ_succ: [],
            quickReplyBtnCount: 0,
        };
    },
    validations() {
        return {

            // templateBodyL1: {
            //     required: helpers.withMessage('Please enter at least one template body',
            //         requiredIf(
            //             (this.templateBodyL1 == '' || this.templateBodyL1 == null) && (this.templateBodyL2 == '' || this.templateBodyL2 == null) && (this.templateBodyL3 == '' || this.templateBodyL3 == null)
            //         )
            //     )
            // },
            headerstatus: { required: helpers.withMessage('Please enter header status', required) },
            header_file_type: {
                requiredIf: helpers.withMessage("Please select header file type",
                    requiredIf(
                        this.headerstatus == 1
                    )
                ),
            },
            header_file_url: {
                requiredIf: helpers.withMessage("Please upload header file",
                    requiredIf(
                        this.headerstatus == 1 && (this.header_file_url_edit == null || this.header_file_url_edit == '')
                    )
                ),
            },

            staticLink: {
                requiredIf: helpers.withMessage("Please enter static link",
                    requiredIf(
                        this.template_reply_type.value == 2 && this.staticLinkBtnName != ''
                    )
                ),
            },
            staticLinkBtnName: {
                requiredIf: helpers.withMessage("Please enter button name ",
                    requiredIf(
                        this.template_reply_type.value == 2
                    )
                ),
            },

            dynamicLink: {
                requiredIf: helpers.withMessage("Please enter dynamic link",
                    requiredIf(
                        this.template_reply_type.value == 3 && this.dynamicLinkBtnName != ''
                    )
                ),
            },
            dynamicLinkBtnName: {
                requiredIf: helpers.withMessage("Please enter button name ",
                    requiredIf(
                        this.template_reply_type.value == 3
                    )
                ),
            },
            dynamicsLinkVariable: {
                requiredIf: helpers.withMessage("Please enter link variable ",
                    requiredIf(
                        this.template_reply_type.value == 3
                    )
                ),
            },
            otp_min_limit: {
                requiredIf: helpers.withMessage("Please enter minimum number of digits in OTP",
                    requiredIf(function () {
                        return this.template_reply_type.value == 13;
                    })),
            },
            err_reporting_templ_err: {
                requiredIf: helpers.withMessage("Please select template group for error",
                    requiredIf(function () {
                        return this.rowData.mbs4 == 4;
                    })),
            },
            err_reporting_templ_succ: {
                requiredIf: helpers.withMessage("Please select template group for success",
                    requiredIf(function () {
                        return this.rowData.mbs4 == 4;
                    })),
            }
        };
    },

    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    // watch: {
    //     quickBtnCount(BtnCount) {
    //         this.createNumberOfQuickButtons(BtnCount);
    //     }
    // },
    computed: {
        isRequired() {
            return (index) => {
                return this.quickBtnNameArray.length > 0 && this.quickBtnNameArray[index] !== '';
            };
        },
    },
    mounted() {
        this.getLanguages();
        this.currentChatBotData = this.propsDataNew1;
        this.webBotTemplateGroupData = this.propsDataNew2;
        this.totalRecords = this.propsDataNew3;
        this.count = this.propsDataNew4;

        const route = useRoute();
        this.routeParam = route.params.clientSubId;
        this.localClientName = localStorage.localClientName;

        this.getWebBotFlowSettingData();
        this.getTemplateDescriptions();

        if (this.webBotTemplateGroupData != null) {
            this.rowData = this.webBotTemplateGroupData;
            this.onEditWebBotTemplateSettings(this.webBotTemplateGroupData);
        } else {
            this.onAddWebBotTemplateSettings();
            this.totalRecords = []
        }


        // this.getCurrentMultiflowTempList();
    },
    methods: {
        getWebBotFlowSettingData() {
            this.ApiService.getWebBotFlowSettingData({ clientId: this.routeParam, webBotId: this.currentChatBotData.mbu1, mbsId: this.webBotTemplateGroupData.value }).then((data) => {
                if (data.status == 200) {
                    this.webBotFlowListing = data.data;
                    this.LanguageAllowedForThisSetting = [];
                    this.LanguageAllowedForThisSetting = data.languages;
                    this.matchAndExtractLnagugeIds();
                    this.getRegionalLanguage();
                } else {
                    this.webBotFlowListing = '';
                }
            });
        },
        matchAndExtractLnagugeIds() {
            // Split the comma-separated strings into arrays
            const ids = this.templ_lang_ids.split(',').map(Number);
            const vals = this.templ_lang_vals.split(',');

            // Iterate through the languageIds array
            this.LanguageAllowedForThisSetting.forEach(id => {
                // Find the index of the current id in the ids array
                const index = ids.indexOf(id);
                // If the id is found, push the corresponding value from vals to settingLanguageValues
                if (index !== -1) {
                    this.settingLanguageValues.push(vals[index]);
                }
            });

        },
        getLanguages() {
            this.ApiService.getLanguages().then((data) => {
                if (data.status == 200) {
                    this.languageList = data.data;
                } else {
                    this.languageList = '';
                }
            });
        },
        async onEditWebBotTemplateSettings(e) {
            await this.ApiService.getWebBotFlowSettingData({ clientId: this.routeParam, webBotId: this.currentChatBotData.mbu1, mbsId: e.value }).then((data) => {
                if (data.status == 200) {
                    this.webBotFlowListing = data.data;
                    this.LanguageAllowedForThisSetting = [];
                    this.LanguageAllowedForThisSetting = data.languages;
                    this.matchAndExtractLnagugeIds();
                    this.getRegionalLanguage();
                } else {
                    this.webBotFlowListing = '';
                }
            });
            // this.loading = true;
            setTimeout(() => {
                this.formLoaded = true;
            }, 300);
            this.formLoaded = false;
            this.quickBtnTemplateNameArray = [];
            this.template_reply_type = [];
            this.next_template_name = [];
            await this.ApiService.getCurrentWebBotTemplateSettings({ clientId: this.routeParam, webBotId: this.currentChatBotData.mbu1, mbsId: e.value }).then((data) => {
                if (data.status == 200) {
                    this.currentTemplData = data.data;
                    // console.log(this.currentTemplData);
                    this.mbv_row_id = [];
                    // console.log(this.templ_lang_ids);
                    this.templ_lang_ids_count = this.templ_lang_ids.split(',');
                    let i = 0;
                    if (this.currentTemplData != null && this.currentTemplData.length > 0) {
                        this.currentTemplData.forEach((item, index) => {
                            console.log(index);
                            // console.log(item);
                            this.quickReplyBtnCount = item.mbv40;
                            this.mbv_row_id.push(item.mbv1); //tg1
                            if (this.templ_lang_ids.includes(item.mbv42)) {
                                if (i == 0) {
                                    this.headerstatus = 0;
                                    this.header_file_type = null;
                                    this.header_file_url = null;
                                    this.header_file_url_edit = null;
                                    this.profileUrl = null;
                                    this.profileImageError = '';
                                    this.multiple_flow_templ = [];
                                    this.multiFlowIds = [];
                                    this.error_code = [];
                                    this.dialogHeader = "Web Bot Flow Settings";
                                    this.submitted = false;

                                    this.quickBtnCount = '';
                                    let quickBtnCount_id = this.quickBtnCountList.filter(function (items) {
                                        return items.value == item.mbv40;

                                    });
                                    if (quickBtnCount_id.length > 0) {
                                        this.quickBtnCount = quickBtnCount_id[0];
                                    }
                                    //header type
                                    let header_file_type_id = this.headerFileTypeList.filter(function (items) {
                                        return items.value == item.mbv24;
                                    });
                                    if (header_file_type_id.length > 0) {
                                        this.header_file_type = header_file_type_id[0];
                                    }

                                    if (item.mbv17)
                                        this.headerstatus = item.mbv17;
                                    if (item.mbv18 != null || item.mbv18 != '') {
                                        this.header_file_url_edit = item.mbv18;
                                    }

                                    //reply type
                                    this.template_reply_type = [];
                                    if (item.mbv9) {
                                        let template_reply_type_id = this.templatereplytypeList.filter(function (items) {
                                            return items.value == item.mbv9;

                                        });
                                        if (template_reply_type_id.length > 0) {
                                            this.template_reply_type = template_reply_type_id[0];
                                        }
                                    }
                                    //next template name
                                    this.next_template_name = [];
                                    if (item.mbv10) {
                                        let templateEventnextbtn = this.templateEventList.filter(function (items) {
                                            return items.value == item.mbv10;
                                        });
                                        if (templateEventnextbtn.length > 0) {
                                            this.next_template_name = templateEventnextbtn[0];
                                        }
                                    }
                                    // if reply type is selected
                                    if (this.template_reply_type.value) {
                                        // execute if template_reply_type = quick reply btn
                                        if (this.template_reply_type.value == 1) {
                                            this.quickBtnNameArray = [];
                                            if (item.mbv11 != 'null' && item.mbv11 != null)
                                                this.quickBtnNameArray[0] = item.mbv11;
                                            if (item.mbv13 != 'null' && item.mbv13 != null)
                                                this.quickBtnNameArray[1] = item.mbv13;
                                            if (item.mbv15 != 'null' && item.mbv15 != null)
                                                this.quickBtnNameArray[2] = item.mbv15;
                                            if (item.mbv20 != 'null' && item.mbv20 != null)
                                                this.quickBtnNameArray[3] = item.mbv20;
                                            if (item.mbv22 != 'null' && item.mbv22 != null)
                                                this.quickBtnNameArray[4] = item.mbv22;
                                            if (item.mbv27 != 'null' && item.mbv27 != null)
                                                this.quickBtnNameArray[5] = item.mbv27;
                                            if (item.mbv29 != 'null' && item.mbv29 != null)
                                                this.quickBtnNameArray[6] = item.mbv29;
                                            if (item.mbv31 != 'null' && item.mbv31 != null)
                                                this.quickBtnNameArray[7] = item.mbv31;
                                            if (item.mbv33 != 'null' && item.mbv33 != null)
                                                this.quickBtnNameArray[8] = item.mbv33;
                                            if (item.mbv35 != 'null' && item.mbv35 != null)
                                                this.quickBtnNameArray[9] = item.mbv35;

                                            // console.log(this.quickBtnNameArray);
                                            this.quickBtnTemplateNameArray = [];
                                            let templateEventfirstbtn = this.templateEventList.filter(function (items) {
                                                return items.value == item.mbv12;
                                            });
                                            if (templateEventfirstbtn.length > 0) {
                                                this.quickBtnTemplateNameArray[0] = templateEventfirstbtn[0];
                                            }

                                            let templateEventSecondbtn = this.templateEventList.filter(function (items) {
                                                return items.value == item.mbv14;
                                            });
                                            if (templateEventSecondbtn.length > 0) {
                                                this.quickBtnTemplateNameArray[1] = templateEventSecondbtn[0];
                                            }

                                            let templateEventthirdbtn = this.templateEventList.filter(function (items) {
                                                return items.value == item.mbv16;
                                            });
                                            if (templateEventthirdbtn.length > 0) {
                                                this.quickBtnTemplateNameArray[2] = templateEventthirdbtn[0];
                                            }
                                            let templateEventFourthbtn = this.templateEventList.filter(function (items) {
                                                return items.value == item.mbv21;
                                            });
                                            if (templateEventFourthbtn.length > 0) {
                                                this.quickBtnTemplateNameArray[3] = templateEventFourthbtn[0];
                                            }

                                            let templateEventFifthbtn = this.templateEventList.filter(function (items) {
                                                return items.value == item.mbv23;
                                            });
                                            if (templateEventFifthbtn.length > 0) {
                                                this.quickBtnTemplateNameArray[4] = templateEventFifthbtn[0];
                                            }
                                            let templateEventSixthbtn = this.templateEventList.filter(function (items) {
                                                return items.value == item.mbv28;
                                            });
                                            if (templateEventSixthbtn.length > 0) {
                                                this.quickBtnTemplateNameArray[5] = templateEventSixthbtn[0];
                                            }
                                            let templateEventSeventhbtn = this.templateEventList.filter(function (items) {
                                                return items.value == item.mbv30;
                                            });
                                            if (templateEventSeventhbtn.length > 0) {
                                                this.quickBtnTemplateNameArray[6] = templateEventSeventhbtn[0];
                                            }
                                            let templateEventEightbtn = this.templateEventList.filter(function (items) {
                                                return items.value == item.mbv32;
                                            });
                                            if (templateEventEightbtn.length > 0) {
                                                this.quickBtnTemplateNameArray[7] = templateEventEightbtn[0];
                                            }
                                            let templateEventNinthbtn = this.templateEventList.filter(function (items) {
                                                return items.value == item.mbv34;
                                            });
                                            if (templateEventNinthbtn.length > 0) {
                                                this.quickBtnTemplateNameArray[8] = templateEventNinthbtn[0];
                                            }
                                            let templateEventTenthbtn = this.templateEventList.filter(function (items) {
                                                return items.value == item.mbv36;
                                            });
                                            if (templateEventTenthbtn.length > 0) {
                                                this.quickBtnTemplateNameArray[9] = templateEventTenthbtn[0];
                                            }
                                        }


                                        // execute if template_reply_type = Static Link Btn
                                        this.staticLinkBtnName = '';
                                        this.staticLink = '';
                                        if (this.template_reply_type.value == 2) {
                                            this.staticLinkBtnName = item.mbv11;
                                            this.staticLink = item.mbv12;
                                        }
                                        // execute if template_reply_type = Dynamic Link Btn
                                        this.dynamicLinkBtnName = '';
                                        this.dynamicsLinkVariable = '';
                                        this.dynamicLink = '';
                                        if (this.template_reply_type.value == 3) {
                                            this.dynamicLinkBtnName = item.mbv11;
                                            this.dynamicsLinkVariable = item.mbv39;
                                            this.dynamicLink = item.mbv12;
                                        }

                                        // execute if template_reply_type = OTP
                                        this.otp_min_limit = '';
                                        if (this.template_reply_type.value == 13) {
                                            this.otp_min_limit = item.mbv37;
                                            this.otp_max_limit = item.mbv38;
                                        }
                                        // execute if template_reply_type = Text
                                        if (this.template_reply_type.value == 14) {
                                            this.text_max_char_limit = item.mbv38;
                                        }
                                    }
                                }
                                if (item.mbv42 == 1) {
                                    this.newQuickBtnNameArrayR1 = [];
                                    // this.templateBodyL1 = item.tg4;

                                    if (item.mbv11 != 'null' && item.mbv11 != null)
                                        this.newQuickBtnNameArrayR1[0] = item.mbv11;
                                    if (item.mbv13 != 'null' && item.mbv13 != null)
                                        this.newQuickBtnNameArrayR1[1] = item.mbv13;
                                    if (item.mbv15 != 'null' && item.mbv15 != null)
                                        this.newQuickBtnNameArrayR1[2] = item.mbv15;
                                    if (item.mbv20 != 'null' && item.mbv20 != null)
                                        this.newQuickBtnNameArrayR1[3] = item.mbv20;
                                    if (item.mbv22 != 'null' && item.mbv22 != null)
                                        this.newQuickBtnNameArrayR1[4] = item.mbv22;
                                    if (item.mbv27 != 'null' && item.mbv27 != null)
                                        this.newQuickBtnNameArrayR1[5] = item.mbv27;
                                    if (item.mbv29 != 'null' && item.mbv29 != null)
                                        this.newQuickBtnNameArrayR1[6] = item.mbv29;
                                    if (item.mbv31 != 'null' && item.mbv31 != null)
                                        this.newQuickBtnNameArrayR1[7] = item.mbv31;
                                    if (item.mbv33 != 'null' && item.mbv33 != null)
                                        this.newQuickBtnNameArrayR1[8] = item.mbv33;
                                    if (item.mbv35 != 'null' && item.mbv35 != null)
                                        this.newQuickBtnNameArrayR1[9] = item.mbv35;
                                }
                                else if (item.mbv42 == 5) {
                                    this.newQuickBtnNameArrayR2 = [];

                                    if (item.mbv11 != 'null' && item.mbv11 != null)
                                        this.newQuickBtnNameArrayR2[0] = item.mbv11;
                                    if (item.mbv13 != 'null' && item.mbv13 != null)
                                        this.newQuickBtnNameArrayR2[1] = item.mbv13;
                                    if (item.mbv15 != 'null' && item.mbv15 != null)
                                        this.newQuickBtnNameArrayR2[2] = item.mbv15;
                                    if (item.mbv20 != 'null' && item.mbv20 != null)
                                        this.newQuickBtnNameArrayR2[3] = item.mbv20;
                                    if (item.mbv22 != 'null' && item.mbv22 != null)
                                        this.newQuickBtnNameArrayR2[4] = item.mbv22;
                                    if (item.mbv27 != 'null' && item.mbv27 != null)
                                        this.newQuickBtnNameArrayR2[5] = item.mbv27;
                                    if (item.mbv29 != 'null' && item.mbv29 != null)
                                        this.newQuickBtnNameArrayR2[6] = item.mbv29;
                                    if (item.mbv31 != 'null' && item.mbv31 != null)
                                        this.newQuickBtnNameArrayR2[7] = item.mbv31;
                                    if (item.mbv33 != 'null' && item.mbv33 != null)
                                        this.newQuickBtnNameArrayR2[8] = item.mbv33;
                                    if (item.mbv35 != 'null' && item.mbv35 != null)
                                        this.newQuickBtnNameArrayR2[9] = item.mbv35;
                                }
                                else {
                                    this.newQuickBtnNameArrayR3 = [];

                                    if (item.mbv11 != 'null' && item.mbv11 != null)
                                        this.newQuickBtnNameArrayR3[0] = item.mbv11;
                                    if (item.mbv13 != 'null' && item.mbv13 != null)
                                        this.newQuickBtnNameArrayR3[1] = item.mbv13;
                                    if (item.mbv15 != 'null' && item.mbv15 != null)
                                        this.newQuickBtnNameArrayR3[2] = item.mbv15;
                                    if (item.mbv20 != 'null' && item.mbv20 != null)
                                        this.newQuickBtnNameArrayR3[3] = item.mbv20;
                                    if (item.mbv22 != 'null' && item.mbv22 != null)
                                        this.newQuickBtnNameArrayR3[4] = item.mbv22;
                                    if (item.mbv27 != 'null' && item.mbv27 != null)
                                        this.newQuickBtnNameArrayR3[5] = item.mbv27;
                                    if (item.mbv29 != 'null' && item.mbv29 != null)
                                        this.newQuickBtnNameArrayR3[6] = item.mbv29;
                                    if (item.mbv31 != 'null' && item.mbv31 != null)
                                        this.newQuickBtnNameArrayR3[7] = item.mbv31;
                                    if (item.mbv33 != 'null' && item.mbv33 != null)
                                        this.newQuickBtnNameArrayR3[8] = item.mbv33;
                                    if (item.mbv35 != 'null' && item.mbv35 != null)
                                        this.newQuickBtnNameArrayR3[9] = item.mbv35;
                                }
                                if (this.rowData.mbs4 == 4) {
                                    this.err_reporting_templ_succ = [];
                                    if (item.mbv43) {
                                        let errorReportingTemp = this.templateEventList.filter(function (items) {
                                            return items.value == item.mbv43;
                                        });
                                        if (errorReportingTemp.length > 0) {
                                            this.err_reporting_templ_succ = errorReportingTemp[0];
                                        }
                                    }

                                    if (item.mbv44) {
                                        this.err_reporting_templ_err = [];
                                        let errorReportingTemp = this.templateEventList.filter(function (items) {
                                            return items.value == item.mbv44;
                                        });
                                        if (errorReportingTemp.length > 0) {
                                            this.err_reporting_templ_err = errorReportingTemp[0];
                                        }
                                    }
                                }
                                i++;
                            }

                        });
                        if (this.quickReplyBtnCount > 0) {
                            // this.quickBtnCount = { "label": this.quickReplyBtnCount, "value": this.quickReplyBtnCount }
                            this.createNumberOfQuickButtons();
                        }
                    }

                    if (this.rowData.mbs4 == 2 || this.rowData.mbs4 == 5) {
                        this.getCurrentMultiflowTempList();
                        // let count = 0
                        let firstLanguage = this.templ_lang_ids.split(",")[0];
                        // this.ApiService.getCurrentWebBotsAppMultiflowTemplates({ clientId: this.routeParam, templateForUniqueId: e.mbs1, 'laugaugeId': firstLanguage }).then((data) => {
                        this.ApiService.getCurrentWebBotsAppMultiflowTemplates({ clientId: this.routeParam, webBotId: this.currentChatBotData.mbu1, mbsId: this.rowData.value, laugaugeId: firstLanguage }).then((data) => {

                            if (data.status == 200) {
                                this.multiple_flow_templ = [];
                                this.multiFlowIds = [];
                                this.error_code = [];
                                this.currentMultiFlowTempList = data.data;
                                this.multiFlowIds = data.AllLanguagesMultiFlowTempl;
                                if (this.currentMultiFlowTempList != null && this.currentMultiFlowTempList.length > 0) {
                                    this.currentMultiFlowTempList.forEach((data, index1) => {
                                        console.log(index1);

                                        let multiple_flow_templ_id = this.multiflowTempList.filter(function (items) {
                                            return items.value == data.mbv26;

                                        });
                                        if (multiple_flow_templ_id.length > 0) {
                                            this.multiple_flow_templ.push(multiple_flow_templ_id[0]);
                                        }
                                        if (data.mbv41) {
                                            let response_code_id = this.errorCodeList.filter(function (items) {
                                                return items.value == data.mbv41;

                                            });
                                            if (response_code_id.length > 0) {
                                                this.error_code.push(response_code_id[0]);
                                            }
                                        }
                                    });
                                }
                                // this.currentMultiFlowTempList.forEach((data, index1) => {
                                //     console.log(index1);
                                //     if (count >= this.currentMultiflowTempCount) {
                                //         return false; // Exit the forEach loop
                                //     }
                                //     // this.multiFlowIds.push(data.tg1);
                                //     let multiple_flow_templ_id = this.multiflowTempList.filter(function (items) {
                                //         return items.value == data.mbv26;

                                //     });
                                //     if (multiple_flow_templ_id.length > 0) {
                                //         this.multiple_flow_templ.push(multiple_flow_templ_id[0]);
                                //     }
                                //     if (data.mbv41) {
                                //         let response_code_id = this.errorCodeList.filter(function (items) {
                                //             return items.value == data.mbv41;

                                //         });
                                //         if (response_code_id.length > 0) {
                                //             this.error_code.push(response_code_id[0]);
                                //         }
                                //     } else {
                                //         this.error_code.push('');
                                //     }
                                //     count++;
                                // });
                                // console.log(this.multiple_flow_templ);
                                // console.log(this.multiFlowIds);
                                // console.log(this.error_code);
                            } else {
                                this.currentMultiFlowTempList = [];
                                this.multiple_flow_templ = [];
                                this.multiFlowIds = [];
                                this.error_code = [];
                            }
                        });
                    }
                }
            });
        },

        async addNewWebBotTemplate() {
            this.submitted = true;
            this.v$.$validate();
            let formData = new FormData();
            //common data
            // this.createTemplateLanguageArray();
            //header status
            formData.append('mbv17', this.headerstatus);
            if (this.headerstatus == 1) {
                if (this.header_file_url != null) {
                    formData.append('mbv18', this.header_file_url);
                    formData.append('mbv24', this.header_file_type.value);
                }
            } else {
                formData.append('mbv18', '');
                formData.append('mbv24', 0);
            }
            if (this.template_reply_type.value) {
                formData.append('mbv9', this.template_reply_type.value);
            }
            // else {
            //     formData.append('mbv9', '');
            // }
            if (this.next_template_name && this.next_template_name.value) {
                formData.append('mbv10', this.next_template_name.value);
            }

            formData.append('webBotId', this.currentChatBotData.mbu1);
            formData.append('clientId', this.routeParam);
            formData.append('languageIds', this.LanguageAllowedForThisSetting);//this.templ_lang_ids

            if (this.template_reply_type.value == 1) {

                if (this.newQuickBtnNameArrayR1)
                    formData.append('englishBtnNameArray', JSON.stringify(this.newQuickBtnNameArrayR1));
                if (this.newQuickBtnNameArrayR2)
                    formData.append('hindiBtnNameArray', JSON.stringify(this.newQuickBtnNameArrayR2));
                if (this.newQuickBtnNameArrayR3)
                    formData.append('regionalBtnNameArray', JSON.stringify(this.newQuickBtnNameArrayR3));
                if (this.quickBtnTemplateNameArray)
                    formData.append('btnTemplateNameArray', JSON.stringify(this.quickBtnTemplateNameArray));

            }
            if (this.template_reply_type.value == 2) {
                formData.append('mbv11', this.staticLinkBtnName);
                formData.append('mbv12', this.staticLink);
            }
            if (this.template_reply_type.value == 3) {
                formData.append('mbv11', this.dynamicLinkBtnName);
                formData.append('mbv39', this.dynamicsLinkVariable);
                formData.append('mbv12', this.dynamicLink);
            }
            if (this.template_reply_type.value == 13) {
                formData.append('mbv37', this.otp_min_limit);
                formData.append('mbv38', this.otp_max_limit);
            }
            // execute if template_reply_type = Text
            if (this.template_reply_type.value == 14) {
                formData.append('mbv38', this.text_max_char_limit);
            }
            formData.append('mbv26', this.rowData.mbs1);
            formData.append('mbsData', JSON.stringify(this.rowData));

            //if template is multi flow
            if (this.rowData.mbs4 == 2 || this.rowData.mbs4 == 5) {

                if (this.multiFlowIds)
                    formData.append('multiFlowIds', this.multiFlowIds);
                formData.append('mbv26', this.rowData.mbs3);
                if (this.error_code)
                    formData.append('responseCodeArray', JSON.stringify(this.error_code));
                if (this.multiple_flow_templ) {
                    formData.append('multiFlowTemplArray', JSON.stringify(this.multiple_flow_templ));
                }
            }
            //if template is Error Reporting + input Requesting
            if (this.rowData.mbs4 == 4) {
                if (this.err_reporting_templ_succ)
                    formData.append('mbv43', this.err_reporting_templ_succ.value);
                if (this.err_reporting_templ_err)
                    formData.append('mbv44', this.err_reporting_templ_err.value);
            }

            formData.append("mbv1", this.mbv_row_id);
            formData.append('regionLangaugeId', this.regionLangaugeId);
            // for (var pair of formData.entries()) {
            //     console.log(pair[0] + ' - ' + pair[1]);
            // }
            // console.log(this.v$.$error);
            if (!this.v$.$error) {
                this.showLoader = true;
                let result = await this.ApiService.addUpdateWebBotFlowSettingData(formData)
                this.showLoader = false;
                if (result.success) {
                    this.$toast.add({ severity: 'success', summary: 'Success', detail: result.message, life: 3000 });
                    this.wTemplateId = '';
                    this.templ_lang_ids = '1,5,2';
                    this.templ_lang_vals = 'English,Hindi,Marathi';
                    setTimeout(() => {
                        this.v$.$reset();
                    }, 0);
                    if (this.mbv_row_id == 0) {
                        this.goToListing();
                    }
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: "Something went wrong", life: 3000 });
                }
            }
        },

        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            let inputValue = $event.target.value + String.fromCharCode(keyCode);

            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
            else if (inputValue < 3 || inputValue > 6) {
                $event.preventDefault();
            }
        },
        onlyNumberForMaxCharLimit($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },
        showEnglishButtonName(index) {
            // alert(this.quickBtnNameArray);
            // this.quickBtnNameArray = [];
            if (this.newQuickBtnNameArrayR1[index] != '') {
                let value = this.newQuickBtnNameArrayR1[index];
                this.quickBtnNameArray[index] = value;
                // console.log(this.quickBtnNameArray);
            }
        },
        showHindiButtonName(index) {
            if (this.newQuickBtnNameArrayR1[index] == undefined) {
                let value = this.newQuickBtnNameArrayR2[index];
                this.quickBtnNameArray[index] = value;
            }
            else if (this.newQuickBtnNameArrayR1[index] != '') {
                let value = this.newQuickBtnNameArrayR1[index];
                this.quickBtnNameArray[index] = value;
            }
            // console.log(this.quickBtnNameArray);

        },
        showRegionalLangButtonName(index) {
            if (this.newQuickBtnNameArrayR1[index] == undefined) {
                if (this.newQuickBtnNameArrayR2[index] == undefined) {
                    let value = this.newQuickBtnNameArrayR3[index];
                    this.quickBtnNameArray[index] = value;
                }
            }
            else if (this.newQuickBtnNameArrayR1[index] == undefined) {
                if (this.newQuickBtnNameArrayR2[index] != '') {
                    let value = this.newQuickBtnNameArrayR2[index];
                    this.quickBtnNameArray[index] = value;
                }
            }
            else if (this.newQuickBtnNameArrayR1[index] != '') {
                let value = this.newQuickBtnNameArrayR1[index];
                this.quickBtnNameArray[index] = value;
            }
            // console.log(this.quickBtnNameArray);
        },

        createNumberOfQuickButtons() {
            // console.log(BtnCount)
            let BtnCount = this.quickBtnCount;
            this.newQuickBtnLabelArray = [];
            // console.log(this.quickBtnLabelArray);
            if (BtnCount != null && BtnCount != '' && BtnCount.value != undefined) {
                {
                    if (BtnCount.value > 0) {
                        for (let i = 0; i < BtnCount.value; i++) {
                            this.newQuickBtnLabelArray.push(this.quickBtnLabelArray[i]);
                        }
                        this.preventDefault()
                        // console.log(this.newQuickBtnLabelArray);
                    }
                }
            }
        },
        getRegionalLanguage() {
            const array = this.LanguageAllowedForThisSetting;//this.templ_lang_ids.split(',');
            this.regional_lang_flag = false;
            if (array != null && (array.length > 0)) {
                for (let i = 0; i < array.length; i++) {
                    if (array[i] != 1 && array[i] != 5) {
                        let vals = this.languageList.filter(function (items) {
                            return items.value == array[i];
                        });
                        if (vals.length > 0) {
                            this.regional_lang_flag = true;
                            this.regionLangaugeId = vals[0]['value'];
                            return vals[0]['label'];
                        }
                    }
                }
            }
        },
        goToListing() {
            // this.$emit('go_to_listing');
            this.$emit('go_to_previous');
        },

        goToNext() {
            this.count++
            this.getRecordById(this.totalRecords[this.count]['mbs1']);
        },
        goToPrevious() {
            this.count--
            this.getRecordById(this.totalRecords[this.count]['mbs1']);
        },
        getRecordById(id) {
            let mbs1 = id;
            let vals = this.totalRecords.filter(function (items) {
                return items.mbs1 == mbs1;
            });
            this.rowData = vals[0];
            if (vals.length > 0) {
                this.onEditWebBotTemplateSettings(vals[0]);
            }
        },
        getTemplateDescriptions() {
            // this.loading = true;
            this.ApiService.getTemplateDescriptions({ clientId: this.routeParam }).then((data) => {
                if (data.status == 200) {
                    this.templateEventList = data.data;
                } else {
                    this.templateEventList = '';
                }
                // this.loading = false;
            });
        },
        getCurrentMultiflowTempList() {
            this.multiflowTempList = [];
            this.currentMultiflowTempCount = 0;
            this.ApiService.getCurrentMultiflowTempList({ clientId: this.routeParam, groupId: this.rowData.mbs1 }).then((data) => {
                if (data.status == 200) {
                    this.multiflowTempList = data.data;
                    // this.multiflowTempList.shift();
                    this.currentMultiflowTempCount = this.multiflowTempList.length;
                    // console.log(this.currentMultiflowTempCount);
                }
            });
        },
        clearuploadimage() {
            this.profileImageError = '';
            this.header_file_url = null;
            this.profileUrl = '';
            this.header_file_url_edit = null;
        },

        async onSelectProfile(e) {
            if (this.header_file_type.value == 1) {
                this.allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
                this.file = e.files[0];
            } else if (this.header_file_type.value == 2) {
                this.allowedExtensions = /(\.pdf)$/i;
                this.file = this.$refs.docuploadref.files[0];
            } else if (this.header_file_type.value == 3) {
                this.allowedExtensions = /(\.mp4|\.mpeg)$/i;
                this.file = this.$refs.videouploadref.files[0];
            } else if (this.header_file_type.value == 4) {
                this.allowedExtensions = /(\.mp3)$/i;
                this.file = this.$refs.tuneuploadeditref.files[0];
            }

            if (!this.file.size > 2048) {
                this.profileImageError = 'File size cannot exceed more than 2MB';
                this.header_file_url = '';
                this.profileUrl = '';
            } else if (!this.file.name.match(this.allowedExtensions)) {
                this.profileImageError = 'Invalid file type';
                this.header_file_url = '';
                this.profileUrl = '';
            } else {
                this.profileImageError = '';
                if (this.header_file_type.value == 1) {
                    this.header_file_url = e.files[0];
                    this.profileUrl = { url: this.file.objectURL, name: this.file.name };
                }
                else {
                    this.profileUrl = { name: this.file.name };
                    this.header_file_url = this.file;
                }
            }
        },

        onAddWebBotTemplateSettings() {
            this.templateEvent = '';
            // this.templateBody = '';
            this.language = '';
            this.template_reply_type = '';
            this.next_template_name = '';

            this.header_file_type = '';
            this.headerstatus = 0;
            this.header_file_url = null;
            this.header_file_url_edit = null;
            this.profileUrl = null;
            this.submitted = false;
        },
    }

};
</script>
<style scoped>
.loader-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20%;
    z-index: 1000;
}

.loader-icon {
    font-size: 2em;
    color: indigo;
}
</style>
<!-- <style scoped></style> -->
